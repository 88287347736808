import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { FeaturedLocationsProps, FeaturedLocationsFlexModuleResult } from "./typings";
import { FeaturedLocationsMap } from "@shared-ui/retail-cross-links-featured-locations";
import { getParams } from "./get-params";

export const FeaturedLocations = withStores(
  "flexModuleModelStore",
  "flexViewModel",
  "uriContext"
)(
  observer((props: FeaturedLocationsProps) => {
    const { templateComponent, uriContext: uriContextStore, flexModuleModelStore, flexViewModel } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as FeaturedLocationsFlexModuleResult | null;
    const uriContext = uriContextStore.get();

    if (!uriContext || !model || !model.strategy || model.strategy.trim() === "") {
      return null;
    }

    const params = getParams(model, flexViewModel);
    const uriContextWithType = {
      identifiers: uriContext.identifiers,
      attributes: {
        gaiaType: model.gaiaType,
      },
    };

    return (
      <div id={id}>
        <FeaturedLocationsMap
          inputs={{
            parameters: params,
            strategy: model.strategy,
            uriContext: uriContextWithType,
            limit: 10,
          }}
          showCarousels={model.showCarousels}
          carouselTitle={model.carouselTitle}
        />
      </div>
    );
  })
);

export default FeaturedLocations;
