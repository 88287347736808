import { FeaturedLocationsFlexModuleResult } from "./typings";
import { CrossLinksParameterInput } from "__generated__/typedefs";
import { FlexViewModelStore } from "src/stores/flexViewModel/FlexViewModelStore";

export const getParams = (
  model: FeaturedLocationsFlexModuleResult,
  flexViewModel: FlexViewModelStore
): CrossLinksParameterInput[] => {
  const { destinationIds, title, filterExternalIds, useUserLocation, pageType } = model;
  const { userLocation } = flexViewModel;

  const params: CrossLinksParameterInput[] = [
    {
      name: "header",
      value: title,
    },
  ];

  if (destinationIds) {
    params.push({
      name: "destinationIds",
      value: destinationIds,
    });
  }

  if (useUserLocation && userLocation) {
    params.push({
      name: "coordinates",
      value: `${userLocation.latitude},${userLocation.longitude}`,
    });
  }

  if (filterExternalIds) {
    const commaSeparated = filterExternalIds.join(",");
    if (commaSeparated) {
      params.push({
        name: "filters",
        value: commaSeparated,
      });
    }
  }

  if (pageType) {
    params.push({
      name: "pageType",
      value: pageType,
    });
  }

  return params;
};
